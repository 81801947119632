<style lang="less">
.go-back{
    width: 2rem;
    height: 1.6rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background: rgba(255,151,106,0.5);
    position :fixed;
    top: 2rem;
    left: 0;
    z-index: 3000;
    color: #fff;
    text-align: center;
    line-height: 1.5rem;
    box-shadow: 0.02rem 0.02rem 0.1rem #999999
}
.icon-chexiao{
    font-size: 1.2rem;
}
</style>
<template>
    <div class="go-back" @click="handleGoCart">
        <span class="iconfont icon-chexiao"></span>
    </div>
</template>
<script>
export default {
    name:'back-btn',
    methods:{
        handleGoCart(){
            if(window.history.length <= 1) {
                this.$router.push({
                    name: 'index'
                })
            }else{
                this.$router.back()
            }
        }
    }
}
</script>